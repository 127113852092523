import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Lightbox from 'react-image-lightbox';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Grid,
  Pagination,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  Box,
  Modal,
  Snackbar,
  Menu,
  MenuItem,
  IconButton,
} from '@mui/material';
import 'react-image-lightbox/style.css';

// Icons and Images
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { boardIcon, messageIcon } from '../../assets/images/iconsQuickAction';
import {
  attachmentIcon,
  emptyMessageIcon,
  expireIcon,
  imageIcon,
} from '../../assets/images/icons';

// Components, styles and others
import MessageService from '../../services/napoleon/MessageService';
import { percentage, formatDate, isValidDate } from '../../utils';
import {
  Text,
  ButtonComponent,
  Loading,
  ImageHeader,
  Link,
  EmptyList,
  Alert,
  RadioButton,
  Tag,
  ErrorState,
} from '../../components';
import DateInput from '../../components/Form/DateInput';
import ListCollaborators from '../ListCollaborators';
import GlobalStyles from '../../styles/GlobalStyles';
import { FlexDirectionRowCenter, TableRowStyled } from '../../styles/components';
import { Img, ImgMessage, styles } from './styles';
import '../../styles/table.css';
import './styles.css';

export default function Mural(props) {
  const isMural = props.location.pathname.split('/').reverse()[0] === 'mural';
  const pageTitle = isMural ? 'Mural' : 'Mensagens Diretas';
  const pageIcon = isMural ? boardIcon : messageIcon;
  const history = useHistory();
  const didMount = useRef(false);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const messageService = new MessageService();
  const messagesDispatch = useDispatch();
  const rowsPerPage = useSelector((state) => state.messagesReducer.rowsPerPage);
  const [page, setPage] = useState(1);
  const [messages, setMessages] = useState(null);
  /* eslint-disable */
  const [radioOrderOptions, setRadioOrderOptions] = useState([
    { id: 1, text: 'Mais recentes', value: 'DESC', selected: true },
    { id: 2, text: 'Mais antigas', value: 'ASC', selected: false },
  ]);
  const [radioStatusOptions, setRadioStatusOptions] = useState([
    { id: 1, text: 'Todas', value: 'all', selected: true, },
    { id: 2, text: 'Ativas', value: 'active', selected: false, },
    { id: 3, text: 'Expiradas', value: 'expired', selected: false, },
    { id: 4, text: 'Excluídas', value: 'deleted', selected: false, },
  ]);
  /* eslint-enable */
  const [startDate, setStartDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [statusMessage, setStatusMessage] = useState(radioStatusOptions[0].value);
  const [orderMessages, setOrderMessages] = useState(radioOrderOptions[0].value);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenuOptions = Boolean(anchorEl);
  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleClickOptions = (event) => setAnchorEl(event.currentTarget);
  const handleCloseOptions = () => setAnchorEl(null);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [alertOptions, setAlertOptions] = useState({
    severity: '',
    snackbarText: '',
    duration: 3000,
    icon: '',
  });
  const deletedStatus = { value: 'deleted', label: 'Excluída' };
  const [isOpen, setIsOpen] = useState(false);
  const [showCollaboratorList, setShowCollaboratorList] = useState(false);

  const handleClickNewPost = () => {
    if (isMural) {
      history.push('/admin/create-message', { path: 'mural' });
    } else {
      setShowCollaboratorList(true);
    }
  };

  const handleClickSnackbar = ({
    severity,
    snackbarText,
    duration,
    icon,
  }) => {
    setAlertOptions({
      severity,
      snackbarText,
      duration: duration || 3000,
      icon,
    });
    setOpenSnackBar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  const listMessages = () => {
    setLoading(true);
    const path = isMural ? 'general' : 'direct';
    const start = startDate ? moment(startDate).format('YYYY-MM-DD 00:00:01') : undefined;
    const end = finalDate ? moment(finalDate).format('YYYY-MM-DD 23:59:59') : undefined;

    messageService
      .getMessagesList(page, 10, statusMessage, orderMessages, start, end, path)
      .then((response) => {
        setHasError(false);
        const { docs } = response.data;
        messagesDispatch({
          type: 'FIND_MESSAGES',
          messages: docs,
          rowsPerPage: response.data.pages,
        });
        setMessages(docs);
      })
      .catch((error) => {
        setHasError(true);
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteMessage = () => {
    messageService.deleteMessage(selectedMessage.id)
      .then(() => {
        const messagesTemp = messages;
        /* eslint-disable */
        messagesTemp.map((item) => {
          if (item.id == selectedMessage.id)
            item.status = deletedStatus;
          });
        /* eslint-enable */
        setMessages(messagesTemp);
        setSelectedMessage(null);
        handleClickSnackbar({ severity: 'success', snackbarText: 'Mensagem excluída com sucesso!' });
        setOpenModalDelete(false);
      })
      .catch((error) => {
        throw error;
      });
  };

  const StatusCell = ({ message }) => {
    const { status } = message;
    let color = null;

    /* Active */
    if (status.value === radioStatusOptions[1].value) {
      color = 'primary';
    }

    /* Expired */
    if (status.value === radioStatusOptions[2].value) {
      color = 'secondary';
    }

    /* Deleted */
    if (status.value === radioStatusOptions[3].value) {
      color = 'danger';
    }

    return <Tag color={color}>{status.label}</Tag>;
  };

  const shouldNavigate = (event, message) => {
    const html = event.target.outerHTML.substring(0, 10);
    // When the user clicked in the More options Icon should navigate
    if (html.includes('<td') || html.includes('<span') || html.includes('<img')) {
      setSelectedMessage(message);
      setOpenModalDetail(true);
    }
  };

  const downloadFile = (event, attachment) => {
    handleClickSnackbar({ severity: null, snackbarText: 'Baixando...', duration: 10000 });
    event.preventDefault();
    const { url, fileName } = attachment;
    fetch(url)
      .then((response) => {
        response.blob().then((blob) => {
          const downloadUrl = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = downloadUrl;
          a.download = fileName;
          a.click();
        });
      })
      .finally(() => handleCloseSnackbar());
  };

  function collaboratorName(message) {
    if (message && message.messageRecipient?.collaborator?.name) {
      return message.messageRecipient.collaborator.name;
    }
    return 'Nome não cadastrado';
  }

  const MessageSectors = ({ sectors }) => {
    if (!sectors || sectors.length === 0) {
      return (
        <div style={styles.sectorsContainer}>
          <Tag color='secondary' variant='hintText'>Todos</Tag>
        </div>
      );
    }

    return (
      <div style={styles.sectorsContainer}>
        {
          sectors.map((sectorItem, key) => (
            <Tag color='secondary' variant='hintText' key={key}>{sectorItem.sector.name.trim()}</Tag>
          ))
        }
      </div>
    );
  };

  useEffect(() => {
    if (didMount.current) {
      listMessages();
    } else {
      didMount.current = true;
    }
  }, [page, statusMessage, orderMessages]);

  useEffect(() => {
    if (!startDate && !finalDate) {
      listMessages();
      return;
    }

    if (!startDate || !finalDate) return;

    try {
      const startDateISOString = startDate.toISOString();
      const finalDateISOString = finalDate.toISOString();
      const yearStartDate = startDateISOString.substring(0, 10).split('-')[0];
      const yearFinalDate = finalDateISOString.substring(0, 10).split('-')[0];

      if (yearStartDate < 1000 || yearFinalDate < 1000) return;

      if (startDate.getTime() > finalDate.getTime()) {
        handleClickSnackbar({ severity: 'error', snackbarText: 'A data inicial não pode ser maior do que a data final!' });
        return;
      }

      if (isValidDate(startDate.toISOString()) && isValidDate(finalDate.toISOString())) {
        listMessages();
      }
    } catch (error) {
      /* eslint-disable */
      return error;
      /* eslint-enable */
    }
  }, [startDate, finalDate]);

  const render = () => {
    if (hasError) return <ErrorState msg='Recarregue a página para ver as mensagens do mural.' />;

    if (loading) return <Loading />;

    if (showCollaboratorList && !isMural) return <ListCollaborators route="Message" />;

    return (
      <Container>
        <Grid
          container
          columnSpacing={2}
          rowSpacing={1}
          style={GlobalStyles.justifyContentRowCenter}
        >
          {/* Title and insert button */}
          <Grid item xs={12}>
            <Grid container style={GlobalStyles.justifyContentRowSpaceEvenly}>
              <Grid item xs={6} sm={9.5} style={GlobalStyles.justifyContentColumnStart}>
                <FlexDirectionRowCenter>
                  <ImageHeader src={pageIcon} />
                  <Text variant='h2'>{pageTitle}</Text>
                </FlexDirectionRowCenter>
              </Grid>
              <Grid item xs={6} sm={2.5} style={GlobalStyles.justifyContentColumnFlexEnd}>
                <ButtonComponent onClick={handleClickNewPost} size='large'>Nova Postagem</ButtonComponent>
              </Grid>
            </Grid>
          </Grid>

          {/* Initial date */}
          <Grid item xs={6} sm={2.5} md={1.5} style={GlobalStyles.justifyContentRowStart}>
            <DateInput
              label='Data inicial'
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              disableFuture
              inputFormat='dd/MM/yyyy'
            />
          </Grid>

          {/* Final date */}
          <Grid item xs={6} sm={2.5} md={1.5} style={GlobalStyles.justifyContentRowStart}>
            <DateInput
              label="Data final"
              value={finalDate}
              onChange={(newValue) => setFinalDate(newValue)}
              inputFormat='dd/MM/yyyy'
            />
          </Grid>

          {/* Filter to order messages */}
          <Grid item xs={12} sm={3} sx={styles.spacingLeft}>
            <Text variant='hintText' style={styles.messageOptionsFilterSpacing}>Período</Text>
            <Grid container style={GlobalStyles.justifyContentRowStart}>
              {
                radioOrderOptions.map((item, key) => (
                  <RadioButton
                    text={item.text}
                    selected={item.selected}
                    key={key}
                    onClick={() => {
                      const options = [...radioOrderOptions];
                      if (options[key].selected) return;
                      options[key].selected = true;
                      setOrderMessages(options[key].value);
                      setPage(1);
                      /* eslint-disable */
                      options.map((option, idx) => {
                        if (idx !== key)
                          option.selected = false;
                      })
                      /* eslint-enable */
                      setRadioOrderOptions(options);
                    }}
                  />
                ))
              }
            </Grid>
          </Grid>

          {/* Filters about the status of the message */}
          <Grid item xs={12} sm={4}>
            <Text variant='hintText' style={styles.messageOptionsFilterSpacing}>Status</Text>
            <Grid container style={GlobalStyles.justifyContentRowStart}>
              {
                radioStatusOptions.map((item, key) => (
                  <RadioButton
                    text={item.text}
                    selected={item.selected}
                    key={key}
                    onClick={() => {
                      const options = [...radioStatusOptions];
                      if (options[key].selected) return;
                      options[key].selected = true;
                      setStatusMessage(options[key].value);
                      /* eslint-disable */
                      options.map((option, idx) => {
                        if (idx !== key)
                          option.selected = false;
                      })
                      /* eslint-enable */
                      setRadioStatusOptions(options);
                    }}
                  />
                ))
              }
            </Grid>
          </Grid>

          {
            messages && messages.length > 0
              ? (
                <>
                  {/* Pagination */}
                  <Grid container item>
                    <Grid item xs={12} style={GlobalStyles.justifyContentRowFlexEnd}>
                      <Pagination
                        defaultPage={6}
                        boundaryCount={1}
                        siblingCount={1}
                        page={page}
                        onChange={handleChangePage}
                        count={rowsPerPage}
                        color='primary'
                        size='small'
                      />
                    </Grid>
                  </Grid>

                  {/* Table */}
                  <Grid container style={styles.muralContent}>
                    <Grid item xs={12}>
                      <TableContainer>
                        <Table aria-label="customized table">
                          <TableBody>
                            {/* eslint-disable */}
                            {
                              messages.map((message, key) => (
                                <TableRowStyled key={key} onClick={(event) => shouldNavigate(event, message)}>
                                  <TableCell>
                                    {moment(message.createdAt).format('DD/MM/YYYY')} - {moment(message.createdAt).format('HH:mm')}
                                  </TableCell>
                                  {!isMural && (
                                    <TableCell style={styles.collaboratorName}>{collaboratorName(message)}</TableCell>
                                  )}
                                  <TableCell>{message.subject}</TableCell>
                                  {isMural && (
                                    <TableCell><MessageSectors sectors={message.sectors}/></TableCell>
                                  )}

                                  <TableCell style={styles.statusCell}><StatusCell message={message} /></TableCell>

                                  {isMural ? (
                                    <TableCell>{percentage(message.readRate)}%</TableCell>
                                  ) : (
                                    <TableCell>
                                      {message.readed ? (
                                        <DoneAllOutlinedIcon color='primary' fontSize='small' />
                                      ) : (
                                        <CheckOutlinedIcon color='primary' fontSize='small' />
                                      )}
                                    </TableCell>
                                  )}
                                  <TableCell>{message.expirectedAt && <Img src={expireIcon} />}</TableCell>
                                  <TableCell>{message.thumbnail && <Img src={imageIcon} />}</TableCell>
                                  <TableCell>{message.attachments.length > 0 && <Img src={attachmentIcon} />}</TableCell>
                                  <TableCell>
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls={openMenuOptions ? 'long-menu' : undefined}
                                      aria-expanded={openMenuOptions ? 'true' : undefined}
                                      aria-haspopup="true"
                                      onClick={(event) => { handleClickOptions(event); setSelectedMessage(message) }}
                                    >
                                      <MoreHorizIcon color='primary' fontSize='small' />
                                    </IconButton>
                                  </TableCell>
                                </TableRowStyled>
                              ))
                            }
                            {/* eslint-disable */}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      <Menu
                        id="long-menu"
                        MenuListProps={{ 'aria-labelledby': 'long-button' }}
                        anchorEl={anchorEl}
                        open={openMenuOptions}
                        onClose={handleCloseOptions}
                        PaperProps={{ style: styles.optionsPopover }}
                      >
                        <MenuItem onClick={() => { setOpenModalDetail(true); handleCloseOptions(); }}>
                          <InfoOutlinedIcon color='secondary_light' fontSize='small'/>&nbsp;
                          <Text variant='hintText'>Informações</Text>
                        </MenuItem>

                        <MenuItem
                          onClick={() => { setOpenModalDelete(true); handleCloseOptions(); }}
                          disabled={selectedMessage && selectedMessage.status.value == deletedStatus.value}
                        >
                          <DeleteIcon color='secondary_light' fontSize='small'/>&nbsp;
                          <Text variant='hintText'>Excluir</Text>
                        </MenuItem>
                      </Menu>
                    </Grid>
                  </Grid>
                </>
              ) : <EmptyList srcImage={emptyMessageIcon} text='Não há nenhuma mensagem no mural.' />
          }
        </Grid>

        <Modal
          open={openModalDelete}
          onClose={() => setOpenModalDelete(false)}
          onBackdropClick={() => setOpenModalDelete(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles.modal}>
            <Text id="modal-modal-title" variant="h2" textAlign='center'>
              Atenção
            </Text>
            <Text id="modal-modal-description" variant='paragraph' textAlign='center' style={styles.modalDeleteText}>
              Tem certeza que deseja excluir a mensagem?
            </Text>

            <div style={GlobalStyles.justifyContentRowSpaceEvenly}>
              <ButtonComponent onClick={() => setOpenModalDelete(false)} color='primary_light'>
                Fechar
              </ButtonComponent>
              <ButtonComponent onClick={deleteMessage} color='primary'>
                Excluir
              </ButtonComponent>
            </div>
          </Box>
        </Modal>

        <Modal
          open={openModalDetail}
          onClose={() => setOpenModalDetail(false)}
          onBackdropClick={() => setOpenModalDetail(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles.modalDetail}>
            <Grid container spacing={1} style={styles.justifyContent}>
              {/* Header */}
              <Grid item xs={12}>
                <Grid container style={GlobalStyles.justifyContentRowSpaceEvenly}>
                  {/* Path */}
                  <Grid item xs={6} sm={9.5} style={GlobalStyles.justifyContentColumnStart}>
                    <Text variant='h4'>Mural {'>'} Detalhes da mensagem</Text>
                  </Grid>

                  {/* Status / Options / Close */}
                  <Grid item xs={6} sm={2.5} style={GlobalStyles.justifyContentRowFlexEndAlignCenter}>
                    <StatusCell message={selectedMessage} />
                    {/* to do */}
                    {/* <IconButton><MoreHorizIcon /></IconButton> */}
                    <IconButton onClick={() => setOpenModalDetail(false)}>
                      <CloseOutlinedIcon color='secondary' />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container xs={12}>
                <Grid item xs={12} sm={9}>
                  <Grid item container xs={12}>
                    <Text variant='h3' color='secondary'>{selectedMessage?.subject}</Text>
                  </Grid>

                  <Grid item container xs={12} style={styles.marginTop} spacing={1}>
                    <Grid item xs={6} sm={3} style={GlobalStyles.justifyContentColumnStart}>
                      <Text variant='h4' color='secondary'>Data e horário</Text>
                      <Text variant='hintText'>{formatDate(selectedMessage?.createdAt)}</Text>
                    </Grid>

                    <Grid item xs={6} sm={2} style={GlobalStyles.justifyContentColumnStart}>
                      <Text variant='h4' color='secondary'>
                        {selectedMessage?.status.value === radioStatusOptions[2].value ? 'Expirou em' : 'Expira em'}
                      </Text>
                      <Text variant='hintText'>
                        {selectedMessage?.expirectedAt ? moment(selectedMessage.expirectedAt).format('DD/MM/YYYY') : 'Não expira'}
                      </Text>
                    </Grid>

                    <Grid item xs={6} sm={2} style={GlobalStyles.justifyContentColumnStart}>
                      <Text variant='h4' color='secondary'>Postado por</Text>
                      <Text variant='hintText'>{selectedMessage?.senderName}</Text>
                    </Grid>

                    <Grid item xs={6} sm={2} style={GlobalStyles.justifyContentColumnStart}>
                      <Text variant='h4' color='secondary'>{isMural ? 'Visualizações' : 'Visualizado'}</Text>
                      <Text variant='hintText'>
                        {isMural ? (
                          percentage(selectedMessage?.readRate) + '%'
                        ) : selectedMessage?.collaboratorMessageView && selectedMessage.collaboratorMessageView?.viewedAt
                            ? formatDate(selectedMessage.collaboratorMessageView.viewedAt)
                            : 'Não'
                        }
                      </Text>
                    </Grid>

                    <Grid item xs={6} sm={2} style={GlobalStyles.justifyContentColumnStart}>
                      <Text variant='h4' color='secondary'>Categoria</Text>
                      <Text variant='hintText'>{selectedMessage?.category || 'Nenhuma'}</Text>
                    </Grid>

                    {isMural && (
                      <Grid item xs={12} sm={2} style={GlobalStyles.justifyContentColumnStart}>
                        <Text variant='h4' color='secondary'>Setores</Text>
                        <Text variant='hintText'>
                          {
                            selectedMessage?.sectors?.length > 0 ?
                              selectedMessage.sectors.map((sectorItem, index) => (
                                sectorItem.sector.name.trim() + (index === selectedMessage.sectors.length - 1 ? '' : ', ')
                              )
                            )
                            : 'Todos'
                          }
                        </Text>
                      </Grid>
                    )}

                    { !isMural &&
                    (<Grid item xs={12} style={GlobalStyles.justifyContentColumnStart} marginTop={1}>
                        <Text variant='h4' color='secondary'>Para</Text>
                        <Text variant='hintText'>{collaboratorName(selectedMessage)}</Text>
                      </Grid>
                    )}

                    <Grid item xs={12} style={GlobalStyles.justifyContentColumnStart}>
                      <Grid variant='paragraph' style={styles.messageDescription}>{selectedMessage?.description}</Grid>
                    </Grid>

                    {selectedMessage?.thumbnail && (
                      <Grid item xs={6} sm={3} style={GlobalStyles.justifyContentColumnStart}>
                        <Text variant='h4' color='secondary'>Imagem</Text>
                        <Grid item xs={12} sm={6} style={GlobalStyles.justifyContentColumnStart}>
                          <ImgMessage
                            src={selectedMessage.thumbnailUrl}
                            onClick={() => setIsOpen(true)}
                          />

                          {isOpen && <Lightbox mainSrc={selectedMessage?.thumbnailUrl} onCloseRequest={() => setIsOpen(false)} />}
                        </Grid>
                      </Grid>
                    )}

                    {selectedMessage?.attachments.length > 0 && (
                      <Grid item xs={6} sm={3} style={GlobalStyles.justifyContentColumnStart}>
                        <Text variant='h4' color='secondary'>Anexo</Text>
                        <Grid item xs={12} sm={12}>
                          {selectedMessage?.attachments.map((attachment, key) => (
                            <Grid item xs={12} sm={12} key={key}>
                              <Link onClick={(event) => downloadFile(event, attachment)}>
                                {attachment.fileName}
                              </Link>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Snackbar open={openSnackbar} autoHideDuration={alertOptions.duration} onClose={handleCloseSnackbar}>
          <Alert
            onClose={handleCloseSnackbar}
            severity={alertOptions.severity}
            icon={alertOptions.icon}
            color={alertOptions.severity ? null : 'secondary_light'}
          >
            {alertOptions.snackbarText}
          </Alert>
        </Snackbar>
      </Container>
    );
  };

  return <>{render()}</>;
}
